import React from 'react';
import { useUser } from '../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import PassCard from '../components/PassCard';

const ProfileScreen = () => {
  const { user, clearUser } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    clearUser();
    navigate('/');
  };

  const accessBookedEvents = {
    name: 'Access Your Active Events',
    description: 'Ongoing and previous events at your fingertips'
  };

  const handleActiveEventsClick = () => {
    navigate('/booked-events');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-50 to-gray-200">
      {/* Main Content */}
      <div className="flex-1 flex flex-col items-center p-6">
        {/* Profile Card */}
        <div className="bg-white shadow-lg rounded-xl p-8 w-full max-w-lg text-center border border-gray-200">
          <h2 className="text-2xl font-bold text-gray-800">{user?.fullName}</h2>
          <p className="text-gray-600 mt-1">{user?.email}</p>
          <p className="text-gray-600">{user?.phone}</p>
        </div>

        {/* Pass Card */}
        <div className="mt-6 w-full max-w-lg">
          <PassCard
            pass={accessBookedEvents}
            onPassClick={handleActiveEventsClick}
            bgColor="bg-customPurple"
            textColor="text-white"
            buttonBgColor="bg-white"
            buttonTextColor="text-purple-600"
            btnText="View Tickets"
          />
        </div>

        {/* Redirection Links */}
        <div className="text-center w-full max-w-lg mt-8">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Useful Links</h3>
          <div className="grid grid-cols-2 gap-4">
            {[
              { path: "/about-us", label: "About Us" },
              { path: "/terms-and-conditions", label: "Terms & Conditions" },
              { path: "/privacy-policy", label: "Privacy Policy" },
              { path: "/cancellation-policy", label: "Cancellation/Refund Policy" },
            ].map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className="text-purple-700 font-medium hover:underline transition"
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* Sticky Footer */}
      <footer className="w-full bg-gray-900 text-white py-4 fixed bottom-0 left-0 text-center shadow-lg">
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:bg-red-600 transition duration-300"
        >
          Logout
        </button>
      </footer>
    </div>
  );
};

export default ProfileScreen;
