import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../client';
import { showToastFailure } from '../services/toast';
import LandingPage from './Landing';
import PassScreen from './PassScreen';
import PassDetailsList from './PassDetailsScreen';
import Auth from './Auth';
import ResetPassword from './ResetPassword';
import ProfileScreen from './ProfileScreen';
import AccessActivePass from './AccessActivePassScreen'
import RouteGuard from '../hoc/RouteGuard';
import { useUser } from '../contexts/UserContext';
import ConfirmPayment from './ConfirmPayment';
import AboutUs from './AboutUs';
import TermsConditions from './TermsCondition';
import PrivacyPolicy from './PrivacyPolicy';
import CancellationPolicy from './CancellationPolicy';
import EventDetails from './EventDetails';
import BookedEvents from './BookedEvents';

const AppContent = () => {
  const user = useUser();
  const { user: userData } = user;

  return (  
    <>
    <Routes>
      <Route
        path="/"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/pass"}
          >
            <PassScreen />
          </RouteGuard>
        }
      />
      <Route
        path="/pass"
        element={
          <RouteGuard
            condition={user}
            redirectTo="/"
          >
            <PassScreen />
          </RouteGuard>
        }
      />

      <Route
        path="/tickets"
        element={
          <RouteGuard
            condition={user}
            redirectTo={user ? "/" : "/auth"}
          >
            <PassDetailsList />
          </RouteGuard>
        }
      />

      <Route
        path="/active-pass"
        element={
          <RouteGuard
            condition={user}
            redirectTo={user ? "/" : "/auth"}
          >
            <AccessActivePass />
          </RouteGuard>
        }
      />

      <Route
        path="/event-details"
        element={
          <RouteGuard
            condition={user}
            redirectTo={user ? "/" : "/event-detail"}
          >
            <EventDetails />
          </RouteGuard>
        }
      />

      <Route
        path="/booked-events"
        element={
          <RouteGuard
            condition={user}
            redirectTo={user ? "/" : "/auth"}
          >
            <BookedEvents />
          </RouteGuard>
        }
      /> 

      <Route
        path="/confirm-payment"
        element={
          <RouteGuard
            condition={user}
            redirectTo={user ? "/" : "/auth"}>
            <ConfirmPayment />
          </RouteGuard>
        }
      />

      <Route
        path="/auth"
        element={
          <RouteGuard
          condition={!userData} // Only show auth if user is not logged in
          redirectTo={userData ? "/pass" : "/auth"} // Redirect to Pass if logged in
          >
            <Auth />
          </RouteGuard>
        }
      />

      <Route
        path="/reset-password"
        element={<ResetPassword />}
      />
      <Route
        path="/profile"
        element={
          <RouteGuard
            condition={user}
            redirectTo={user ? "/" : "/auth"}
          >
            <ProfileScreen />
          </RouteGuard>
        }
      />

      <Route
        path="/about-us"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/about-us"}
          >
            <AboutUs />
          </RouteGuard>
        }
      />

      <Route
        path="/terms-and-conditions"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/terms-and-conditions"}
          >
            <TermsConditions />
          </RouteGuard>
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/privacy-policy"}
          >
            <PrivacyPolicy />
          </RouteGuard>
        }
      />

      <Route
        path="/cancellation-policy"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/cancellation-policy"}
          >
            <CancellationPolicy />
          </RouteGuard>
        }
      />

    <Route path="*" element={<PassScreen />} />
    </Routes>

    </>
  );
};

export default AppContent;
