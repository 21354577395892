import React, { useEffect, useState } from 'react';
import { getPasses } from '../services/passService';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';
import PassCard from '../components/PassCard';
import { supabase } from '../client';
import { showToastFailure } from '../services/toast';
import { decrypt } from '../utils/encrypt';
import BannerCarousel from '../components/Banner';
import Footer from '../components/Footer';
import EventsScreen from './Events';
import { fetchEventsAndImages } from '../services/bannerService';

const PassScreen = () => {
  const [passes, setPasses] = useState([]);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const { user, updateUser } = useUser();
  const { showLoader, hideLoader } = useLoading();


  useEffect(() => {
    // fetchEventsAndImages(showLoader, hideLoader, setImages);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accessActivePass = {
    name: 'Access you active pass',
    description: 'On Going Passes / Previous Passes'
  }

  const getCurrentSession = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      return null;
    }
    return data;
  };

  const silentLogin = async () => {
    const cypher = localStorage.getItem('cipher')
    const decipherTxt = decrypt("salt", cypher);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: user?.email,
      password: decipherTxt
    });

    if (error) {
      showToastFailure("failed to re authenticate!");
    } else {
      // Extract user ID from the data object
      const userId = data.user.id;

      // Query the users table to check if the user is a super admin
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', userId)
        .single();
      if (userError) {
        showToastFailure(userError.message);
      } else {
        if (userData.role) {
          updateUser({
            id: userId,
            email: user.email,
            token: data.session.access_token,
            fullName: userData.first_name + userData.last_name,
            phone: userData.phone
          });
        } else {
          showToastFailure('Not authorized to login');
        }
      }
    }

    return data;
  }

  React.useEffect(() => {
    const fetchSession = async () => {
      const currentSession = await getCurrentSession();
      if (!currentSession) {
        silentLogin()
      }
    };
    if (user) {
      fetchSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dependency array


  useEffect(() => {
    const fetchPasses = async () => {
      const fetchedPasses = await getPasses(showLoader, hideLoader);
      setPasses(fetchedPasses);
    };

    fetchPasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePassClick = (pass) => {
    if (user) {
      navigate('/tickets', { state: { passId: pass.id } });
    } else {
      navigate('/auth');
      return;
    }
  }

  const handleActivePassClick = () => {
    navigate('/active-pass');
  }

  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100 ">
       {/*  {images.length > 0 &&  <BannerCarousel images={images} />}  */}
       <div className="flex-grow px-6 py-10">

          {/*   {user && (
            <div className="grid grid-cols-1 gap-6 w-full max-w-lg">
              <PassCard
                pass={accessActivePass}
                onPassClick={handleActivePassClick}
                bgColor="bg-customPurple"
                textColor="text-white"
                buttonBgColor="bg-white"
                buttonTextColor="text-black"
                btnText="View Tickets"
              />
            </div>
          )} */}

          {/* Passes Header
          <h2 className="text-2xl font-semibold text-gray-700 mt-10 mb-5">Available Passes</h2>

          <div className="grid grid-cols-1 gap-6 w-full max-w-lg">
            {passes.length > 0 ? (
              passes.map((pass, index) => (
                <PassCard
                  key={index}
                  pass={pass}
                  onPassClick={handlePassClick}
                  bgColor="bg-white"
                  textColor="text-gray-800"
                  buttonBgColor="bg-customPurple"
                  buttonTextColor="text-white"
                  btnText="Buy Tickets"
                />
            ))
            ) : (
              <p className="text-center text-bold text-red-500">No active passes available at the moment.</p>
            )}
          </div> */}

          <div className="grid grid-cols-1 gap-6 mb-10 w-full max-w-lg">
            <EventsScreen />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PassScreen;