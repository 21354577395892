import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ConfettiExplosion from 'react-confetti-explosion';

const PaymentStatusModal = ({ isSuccess, onClose }) => {
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(isSuccess);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5s
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const handleClose = () => {
    onClose();
    navigate('/pass');
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg text-center shadow-lg relative w-96">
        
        {/* Close Button (Top-Right Corner) */}
        <button 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={handleClose}
        >
          <CloseIcon fontSize="large" />
        </button>

        {isSuccess ? (
          <>
            {/* 🎉 Better Celebration Effect */}
            {showConfetti && (
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2">
                <ConfettiExplosion force={0.8} duration={5000} particleCount={200} width={1600} />
              </div>
            )}

            <div className="text-green-500 mb-4">
              <svg
                className="w-16 h-16 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2l4-4M12 6c3.866 0 7 3.134 7 7s-3.134 7-7 7s-7-3.134-7-7s3.134-7 7-7z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-green-500">Payment Successful!</h2>
            <p>Thank you for your purchase.</p>

            {/* Button below the text */}
            <button
              onClick={() => {
                onClose();
                navigate('/booked-events');
              }}
              className="mt-4 px-6 py-2 bg-customPurple text-white rounded-lg hover:bg-customPurple transition"
            >
              Go to Events
            </button>
          </>
        ) : (
          <>
            <div className="text-red-500 mb-4">
              <svg
                className="w-16 h-16 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-red-500">Payment Failed!</h2>
            <p>Please try again.</p>

            {/* Button below the text */}
            <button
              onClick={() => {
                onClose();
                navigate('/profile');
              }}
              className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
            >
              Go to Profile
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentStatusModal;
