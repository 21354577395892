import React, { useEffect, useState } from 'react';
import { formatIndianCurrency } from '../utils/formatIndianCurrency';
import { useUser } from '../contexts/UserContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLoading } from '../contexts/LoadingContext';
import { createEventsOrder, createRazorPayEventOrder, getCurrentAvailableTickets } from '../services/eventsService';
import { getGST } from '../services/passService';
import { showToastFailure } from '../services/toast';
import Alert from '../modals/Alert';
import moment from "moment";
import PaymentStatusModal from '../modals/PaymentStatusModal';
import { sendEmail } from "../services/emailService";
import { getDDMMYYYYWithTimeFormat } from '../utils/formattedDate';

const EventDetailsScreen = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [taxRate, setTaxRate] = useState();
  const { showLoader, hideLoader } = useLoading();

  const [isPaymentSuccess, setPaymentIsSuccess] = useState(null);
  const [showPaymentModal, setPaymentShowModal] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const handleButtonClick = (value) => {
    setShowAlert(false);
    if (value === 'razor_pay_checkout') {
      checkoutOrder()
    }
  };

  const closeModal = () => {
    setShowAlert(false);
  }


  const checkoutOrder = async () => {
    const availableTickets = await checkTickets();
    if (availableTickets && availableTickets.length && availableTickets[0].no_of_tickets >= selectedTickets) {
      const orderData = { event_id: id, amount: price, gst_amount: gstAmount.toFixed(2), selectedTickets, user_id: user.id }
      const payableOrderData = {
        ...orderData,
        totalPayableAmount: totalPrice.toFixed(2),
        totalGst: gstAmount.toFixed(2)
      }

      const razorPayOrderResponse = await createRazorPayEventOrder(payableAmount, user?.user_id, orderData.event_id, showLoader, hideLoader);
      if (razorPayOrderResponse.id) {
        const options = {
          key: process.env.REACT_APP_RAZOR_PAY,
          currency: "INR",
          name: "NOVA",
          description: `Purchase of ${name}`,
          image: "/logo.svg",
          order_id: razorPayOrderResponse.id,
          notes: {
            user_id: user.id,
            event_id: orderData.event_id,
            amount: orderData.amount,
            gst_amount: orderData.gst_amount,
            created_at: new Date(),
            no_of_ticket_purchased: selectedTickets,
            payment_status: true,
            payment_id: razorPayOrderResponse.id
          },
          handler: async function (response) {
            if (response.razorpay_payment_id) {
              const emailPayload = {
                email: user.email,
                name: user.fullName,
                eventName: name,
                eventDate: getDDMMYYYYWithTimeFormat(event_start_date_time)
              };

              await sendEmail(emailPayload);
              setPaymentIsSuccess(true);
               setPaymentShowModal(true)
            } 

          },
          prefill: {
            name: user?.fullName,
            email: user?.email,
            contact: user?.phone,
          },
          theme: {
            color: "#23102A",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        showToastFailure('Error Processing Payment Please retry again later!')
      }

    } else {
      showToastFailure(`Oops! It looks like the total available tickets are just ${availableTickets[0].no_of_tickets}.`);
    }
  }


  const [alertContent, setAlertContent] = useState({
    title: '',
    description: '',
    buttons: [],
  });

  const location = useLocation();
  const event = location.state?.event; // Ensure event is properly retrieved
  
  useEffect(() => {
    if (!event) {
      navigate('/pass');
      return;
    }
  
    const fetchGSTRate = async () => {
      const gstAmount = await getGST(showLoader, hideLoader);
      setTaxRate(gstAmount[0]?.gst / 100);
    };
  
    fetchGSTRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);
  
  const {
    id,
    name,
    description,
    price,
    no_of_tickets,
    event_start_date_time,
    event_end_time,
    image_url,
  } = event || {}; // Provide an empty object as a fallback

  const checkTickets = async () => {
    return await getCurrentAvailableTickets(id);
  }
  const [selectedTickets, setSelectedTickets] = useState(1); // Default selection to 1 ticket

  // Handle Ticket Selection
  const handleTicketSelection = (e) => {
    setSelectedTickets(Number(e.target.value));
  };

  // Generate dropdown options for number of tickets based on available tickets
  const ticketOptions = Array.from({ length: no_of_tickets }, (_, i) => i + 1);

  // Calculate total price, GST, and final payable amount
  const totalPrice = selectedTickets * price;
  const gstAmount = totalPrice * taxRate;
  const payableAmount = totalPrice + gstAmount;

  const handlePurchaseEvent = async () => {
    const alertContent = {
      title: 'Important Payment Notice',
      description: `Please do not press back or close the browser once your payment is completed. You will be automatically redirected after payment. If you navigate away, your order may not be fulfilled.`,
      buttons: [{ label: 'Understood', value: 'razor_pay_checkout' }],
    }
    setAlertContent(alertContent)
    setShowAlert(true);
  }

  return (
    <>
      <div className="event-details-container">
        {/* Cover Image */}
        {image_url &&
          <div className="cover-image">
            <img src={image_url} alt={name} className="w-full h-60 object-contain" />
          </div>
        }

        {/* Event Details */}
        <div className="event-details p-5 mb-20">
          <h1 className="text-4xl font-bold mb-4">{name}</h1>
          <p className="text-lg text-gray-600 mb-4">{description}</p>

          {/* Event Info */}
          <div className="event-info grid grid-cols-2 gap-4 mb-10">
            <div>
              <h2 className="text-lg font-semibold">Price:</h2>
              <p className="text-md">₹{price} per ticket</p>
            </div>
            {no_of_tickets <= 5 && (
              <div>
                <h2 className="text-lg font-semibold text-red-600">Tickets Left:</h2>
                <p className="text-md font-bold text-red-600">{no_of_tickets}</p>
              </div>
            )}
            <div>
              <h2 className="text-lg font-semibold">Start Date:</h2>
              <p className="text-md">{moment(event_start_date_time).format("hh:mm A")}</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">End Date:</h2>
              <p className="text-md">{moment(event_end_time).format("hh:mm A")}</p>
            </div>
          </div>

          {/* Ticket Selection Dropdown */}
          <div className="ticket-selection mb-4">
            <h2 className="text-lg font-semibold">Select Tickets:</h2>
            <select
              disabled={no_of_tickets === 1}
              value={selectedTickets}
              onChange={handleTicketSelection}
              className={`w-full p-2 border border-gray-300 rounded-lg ${no_of_tickets === 1 ? 'bg-gray-200' : ''
                }`}
            >
              {ticketOptions.map((num) => (
                <option key={num} value={num}>
                  {num} {num === 1 ? 'Ticket' : 'Tickets'}
                </option>
              ))}
            </select>
          </div>

          {/* Display Payable Amount with GST */}
          <div className="payment-info mb-30">
            <h2 className="text-lg font-semibold">Payment Details:</h2>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <p className="text-md">Total Price:</p>
              </div>
              <div>
                <p className="text-md">₹{formatIndianCurrency(totalPrice)}</p>
              </div>
              <div>
                <p className="text-md">GST (18%):</p>
              </div>
              <div>
                <p className="text-md">₹{formatIndianCurrency(gstAmount)}</p>
              </div>
              <div>
                <p className="text-lg font-semibold">Payable Amount:</p>
              </div>
              <div>
                <p className="text-lg font-semibold">₹{formatIndianCurrency(payableAmount)}</p>
              </div>

              <div>
                <Link to="/cancellation-policy">
                  <span className="text-sm  font-semibold underline text-red-500 hover:underline">
                    Cancellation Policy
                  </span>
                </Link>
              </div>


            </div>
          </div>

        </div>

        {/* Purchase Tickets Button */}
        <div className="purchase-tickets-footer fixed bottom-0 left-0 w-full bg-white p-4 border-gray-300">
          <button
            className="w-full bg-customPurple text-white py-3 text-lg font-semibold rounded-lg hover:bg-customPurple transition"
            onClick={() => handlePurchaseEvent()}
          >
            Purchase {selectedTickets} {selectedTickets === 1 ? 'Ticket' : 'Tickets'}
          </button>
        </div>
      </div>

      {showPaymentModal && (
        <PaymentStatusModal
          isSuccess={isPaymentSuccess}
          onClose={() => {
            setPaymentShowModal(false);
            navigate('/pass');
          }}
        />
      )}


      {showAlert && (
        <Alert
          title={alertContent.title}
          description={alertContent.description}
          buttons={alertContent.buttons}
          onButtonClick={handleButtonClick}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default EventDetailsScreen;
