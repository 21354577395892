/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import orderService from '../services/ordersService';
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';
import { showToastFailure } from '../services/toast';
import { supabase } from '../client';
import { SelectDatepicker } from 'react-select-datepicker';
import ReactModal from 'react-modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '../modals/Alert';
import { getRemainingTicketsForSubPasses } from '../services/passService';

const { createOrder, createRazorPayOrder } = orderService;

function ConfirmPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useUser();
    const [isNotesModalOpen, setNotesModalOpen] = useState(false);
    const { showLoader, hideLoader } = useLoading();

    // Set default state from location.state or empty objects
    const [passDetails, setPassDetails] = useState(location.state?.passDetails || {});
    const [orderData, setOrderData] = useState(location.state?.orderData || {});
    const [ticketDetails, setTicketDetails] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState({
        title: '',
        description: '',
        buttons: [],
    });

    const handleButtonClick = (value) => {
        setShowAlert(false);

        if (value === 'checkout_order') {
            checkoutOrder()
        } else {
            navigate('/pass', { replace: true })
        }
    };

    useEffect(() => {
        if (orderData && orderData.no_of_ticket_purchased >= 2) {
            openModal();
        }
    }, [orderData, orderData.no_of_ticket_purchased])


    // Handle changes for each ticket field dynamically
    const handleChange = (index, field, value) => {
        setTicketDetails((prevDetails) => {
            const newDetails = [...prevDetails];
            newDetails[index] = {
                ...newDetails[index],
                [field]: value,
            };
            return newDetails;
        });
    };

    const validateForm = () => {
        let isValid = true;

        // Basic regex for email and mobile validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mobileRegex = /^[6-9]\d{9}$/; // Assuming a 10-digit mobile number starting with 6-9

        ticketDetails?.forEach((ticket, index) => {
            // Check if any one field is filled
            const isAnyFieldFilled = ticket?.name || ticket?.dob || ticket?.email || ticket?.mobile;
            const isAllFieldsFilled = ticket?.name && ticket?.dob && ticket?.email && ticket?.mobile;

            // Validate email and mobile format
            const isEmailValid = emailRegex.test(ticket?.email);
            const isMobileValid = mobileRegex.test(ticket?.mobile);

            // Validate dob (date of birth) - must be a valid date
            const isDobValid = isValidDate(ticket?.dob);

            // If any field is filled but:
            // 1. Not all fields are filled
            // 2. Email, mobile, or dob format is invalid
            if (
                isAnyFieldFilled &&
                (!isAllFieldsFilled || !isEmailValid || !isMobileValid || !isDobValid)
            ) {
                isValid = false;
            }
        });

        return isValid;
    };

    // Function to check if dob is a valid date (should be a valid day, month, and year)
    const isValidDate = (dob) => {
        if (!dob) return false;
        const date = new Date(dob);
        return !isNaN(date.getTime()); // Check if it's a valid date
    };

    const openModal = () => {
        setNotesModalOpen(true);
    };

    // Close Modal handler
    const closeModal = () => {
        setNotesModalOpen(false);
        setShowAlert(false);
    };


    const checkoutOrder = async () => {
        if (!orderData) {
            console.error('Order data is missing!');
            return;
        }
        const filteredTicketDetails = ticketDetails?.filter(item => item !== undefined && item !== null);
        const totalAmt = (parseFloat(orderData?.gst_amount || 0) + parseFloat(orderData?.amount || 0)).toFixed(2)

        if (orderData?.payment_mode === "online") {
            const orderResponseFromRazorPay = await createRazorPayOrder(totalAmt, user?.id, passDetails?.id, showLoader, hideLoader)
            if (orderResponseFromRazorPay) {
                const options = {
                    key: process.env.REACT_APP_RAZOR_PAY,
                    amount: (totalAmt * 100).toFixed(2),
                    currency: "INR",
                    name: "NOVA",
                    description: `Purchase of ${passDetails?.title}`,
                    image: "/app_logo.jpg",
                    order_id: orderResponseFromRazorPay.id,
                    handler: async function (response) {
                        if (response.razorpay_payment_id) {
                            const checkoutData = { ...orderData, payment_id: response.razorpay_payment_id, payment_status: true, ticketDetails: filteredTicketDetails }
                            await createOrder(checkoutData, showLoader, hideLoader)
                            navigate('/pass')
                        } else {
                            const checkoutData = { ...orderData, payment_id: null, payment_status: false }
                            await createOrder(checkoutData, showLoader, hideLoader)
                        }
                    },
                    prefill: {
                        name: user?.fullName,
                        email: user?.email,
                        contact: user?.phone,
                    },
                    theme: {
                        color: "#23102A",
                    },
                    notes:{
                        user_id: user.id,
                        pass_detail_id: orderData.pass_detail_id,
                        amount: orderData.amount,
                        gst_amount: orderData.gst_amount,
                        payment_status: true,
                        created_at: new Date(),
                        no_of_ticket_purchased: orderData.no_of_ticket_purchased,
                        payment_mode: "online",
                        order_confirmed: true,
                        ticketDetails: filteredTicketDetails,
                    }
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }



        } else {
            const checkoutData = { ...orderData, payment_id: null, payment_status: true, ticketDetails: filteredTicketDetails }
            await createOrder(checkoutData, showLoader, hideLoader)
            navigate('/pass')
        }
    }

    const handlePurchase = async () => {

        if (validateForm()) {

                const passData = await getRemainingTicketsForSubPasses(passDetails?.id);
                if (passData && passData?.length && passData[0].no_of_tickets >= Number(orderData.no_of_ticket_purchased)) {


                    if (orderData.payment_mode === 'online') {
                        const alertContent = {
                            title: 'Important Payment Notice',
                            description: `Please do not press back or close the browser once your payment is completed. You will be automatically redirected after payment. If you navigate away, your order may not be fulfilled.`,
                            buttons: [{ label: 'Understood', value: 'checkout_order' }],
                        };

                        setAlertContent(alertContent)
                        setShowAlert(true);
                    } else {
                        checkoutOrder();
                    }


                } else {
                    setAlertContent({
                        title: 'Tickets Update Availability',
                        description: `We currently have only ${passData[0].no_of_tickets} ticket${passData[0].no_of_tickets > 1 ? 's' : ''} available. Please note that your requested quantity exceeds our available stock. we will redirect you to home screen and you can try booking it again.`,
                        buttons: [{ label: 'OK', value: 'ok' }],
                    });
                    setShowAlert(true);
                }
            
        } else {
            showToastFailure("Oops! It looks like some details are incomplete. Please fill in the name, email, phone, and date of birth for each person or leave the entire form blank if you'd like to skip it.");
        }

    };

    return (
        <>
            <div className="p-6">
                <div className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-3xl font-bold text-customPurple">{passDetails?.title}</h2>
                        <span className="text-sm  text-gray-500">Tickets: {orderData?.no_of_ticket_purchased}</span>
                    </div>

                    <p className="text-gray-600 text-lg mb-4">{passDetails?.description}</p>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="p-4 bg-gray-100 rounded-lg">
                            <p className="text-xl font-semibold">Amount</p>
                            <p className="text-lg font-semibold text-green-600">₹{parseFloat(orderData?.amount || 0).toFixed(2)}</p>
                        </div>
                        <div className="p-4 bg-gray-100 rounded-lg">
                            <p className="text-xl font-semibold">GST</p>
                            <p className="text-lg font-semibold text-yellow-500">₹{parseFloat(orderData?.gst_amount || 0).toFixed(2)}</p>
                        </div>
                    </div>

                    <div className="p-4 bg-indigo-100 rounded-lg mt-6">
                        <p className="text-xl font-semibold text-customPurple">Total</p>
                        <p className="text-lg font-semibold text-indigo-700">
                            ₹{(parseFloat(orderData?.gst_amount || 0) + parseFloat(orderData?.amount || 0)).toFixed(2)}
                        </p>
                    </div>
                </div>



                <div className="flex flex-col h-full">
                    <form className="flex-1 overflow-y-auto">
                        {orderData.no_of_ticket_purchased - 1 > 0 &&
                            <>
                                {Array.from({ length: orderData.no_of_ticket_purchased - 1 }, (_, index) => (
                                    <div key={index} className="mb-4 p-4 border rounded-lg shadow-sm bg-white">
                                        <h3 className="text-xl font-semibold mb-4">Person {index + 1}</h3>

                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Full Name</label>
                                            <input
                                                type="text"
                                                value={ticketDetails[index]?.name || ''}
                                                onChange={(e) => handleChange(index, 'name', e.target.value)}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                placeholder="Enter full name"
                                            />
                                        </div>


                                        <div className="mb-4 border border-gray-300 rounded-lg p-4">
                                            <label className="block text-sm font-medium text-gray-700">Date of Birth</label>

                                            <SelectDatepicker
                                                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                                                selectedDate={ticketDetails[index]?.dob}
                                                onDateChange={(e) => {
                                                    if (e) {
                                                        handleChange(index, 'dob', e)
                                                    }
                                                }}
                                                labels={{
                                                    yearPlaceholder: 'Birth Year',
                                                    dayPlaceholder: 'Birth Day',
                                                    monthPlaceholder: 'Birth Month'
                                                }}

                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Email</label>
                                            <input
                                                type="email"
                                                value={ticketDetails[index]?.email || ''}
                                                onChange={(e) => handleChange(index, 'email', e.target.value)}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                placeholder="Enter email"
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Mobile</label>
                                            <input
                                                type="tel"
                                                value={ticketDetails[index]?.mobile || ''}
                                                maxLength={10}
                                                minLength={10}
                                                onChange={(e) => handleChange(index, 'mobile', e.target.value)}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                placeholder="Enter mobile number"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                    </form>
                </div>
            </div>
            <footer className="fixed bottom-0 left-0 w-full bg-customPurple text-white text-center cursor-pointer">
                <div className="flex justify-center space-x-4">
                    <a
                        href="#"
                        onClick={handlePurchase}
                        className="w-full flex justify-center mb-2 mt-3 text-center rounded-lg hover:bg-blue-700 transition duration-200"
                    >
                        <span>Purchase Ticket</span>
                    </a>
                </div>
            </footer>

            <ReactModal
                isOpen={isNotesModalOpen}
                onRequestClose={closeModal}
                contentLabel="Extra Ticket Information"
                className="bg-white p-10 border rounded-lg shadow-lg w-80 mx-auto relative"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            >
                {/* Close Icon Button */}
                <IconButton
                    onClick={closeModal}
                    style={{ position: 'absolute', top: '0', right: '10px' }}  // Changed color to neutral grey
                >
                    <CloseIcon />
                </IconButton>

                {/* Modal content */}
                {orderData.no_of_ticket_purchased - 1 > 0 && (
                    <div>
                        <p className="text-customPurple font-bold">  {/* Changed text color to blue */}
                            You only need to provide details for the extra {orderData.no_of_ticket_purchased - 1} persons joining you. Please fill out the form or feel free to skip it if you prefer.
                        </p>
                    </div>
                )}
            </ReactModal>

            {showAlert && (
                <Alert

                    title={alertContent.title}
                    description={alertContent.description}
                    buttons={alertContent.buttons}
                    onButtonClick={handleButtonClick}
                    onClose={closeModal}
                />
            )}

        </>
    );
}

export default ConfirmPayment;
