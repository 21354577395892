import React from 'react';
import { useUser } from '../contexts/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Header = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const isHomeScreen = location.pathname === '/' || location.pathname === '';
  const isPassOrEventScreen = location.pathname === '/pass' || location.pathname === '/events';

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleHomeClick = () => {
    window.location.href = process.env.REACT_APP_URL;
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <header className="bg-customPurple shadow-md py-4 relative">
      <div className="container mx-auto flex items-center justify-between px-4 relative">
        
        {/* Back or Home Button */}
        <IconButton
          onClick={isPassOrEventScreen ? handleHomeClick : handleBackClick}
          className="flex items-center"
          style={{ backgroundColor: 'transparent' }}
        >
          {isHomeScreen || isPassOrEventScreen ? (
            <img src="/logo.png" alt="Home Icon" className="w-10 h-10" />
          ) : (
            <ArrowBackIcon style={{ color: 'white', fontSize: '40px' }} />
          )}
        </IconButton>

        {/* Centered Logo */}
        <span className="absolute left-1/2 transform -translate-x-1/2 text-base text-white font-bold">
           NOVA
        </span>

        {/* Profile Icon */}
        {user ? (
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={handleProfileClick}
          >
            <AccountCircleIcon className="text-white text-3xl mb-1 hover:text-gray-200 transition duration-300" />
            <span className="text-white text-sm whitespace-nowrap">Profile</span>
          </div>
        ) : (
          <div className="w-10" /> // Placeholder to balance layout
        )}
      </div>
    </header>
  );
};

export default Header;
