import React, { useEffect, useState } from "react";
import { getEvents } from "../services/eventsService";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { useLoading } from "../contexts/LoadingContext";
import moment from "moment";

const EventsScreen = () => {
  const [groupedEvents, setGroupedEvents] = useState({});
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoading();
  const { user } = useUser();

  useEffect(() => {
    const fetchEvents = async () => {
      const fetchedEvents = await getEvents(showLoader,hideLoader);
     

      if (fetchedEvents?.length) {
        const eventData = fetchedEvents.map((event) => ({
          ...event,
          image_url: event?.image_url
            ? `${process.env.REACT_APP_SUPABASEURL}/storage/v1/object/public/images/events/${event.image_url}?width=300&height=200`
            : "/app_logo_white.png",
          eventDate: moment(event.event_start_date_time).format("YYYY-MM-DD"),
        }));

        const grouped = eventData.reduce((acc, event) => {
          if (!acc[event.eventDate]) {
            acc[event.eventDate] = [];
          }
          acc[event.eventDate].push(event);
          return acc;
        }, {});

        setGroupedEvents(grouped);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (event) => {
    navigate(user ? "/event-details" : "/auth", { state: { event } });
  };

  const hasEvents = Object.keys(groupedEvents)?.length > 0;

  return (
    <div className="flex flex-col min-h-screen">
      {hasEvents && (
        <h2 className="text-4xl font-extrabold text-gray-900 mb-8 text-center">
          Upcoming Events
        </h2>
      )}
      <div className="w-full max-w-6xl mx-auto">
        {hasEvents ? (
          Object.keys(groupedEvents).map((date, index) => (
            <div key={date} className="mb-12">
              <h3 className="text-2xl font-semibold text-purple-700 mb-5 border-b-2 border-purple-300 pb-3">
                {moment(date).format("dddd, MMMM Do YYYY")}
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                {groupedEvents[date].map((event, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-xl rounded-lg overflow-hidden hover:shadow-2xl transform hover:scale-105 transition duration-300 cursor-pointer"
                    onClick={() => handleEventClick(event)}
                  >
                    <img
                      src={event.image_url}
                      alt={event.name}
                      className="w-full h-48 object-contain"
                    />
                    <div className="p-5">
                      <p className="text-gray-500 text-sm">
                        {moment(event.event_start_date_time).format("hh:mm A")} -{" "}
                        {moment(event.event_end_time).format("hh:mm A")}
                      </p>
                      <h3 className="text-xl font-bold text-gray-900 mt-2">
                        {event.name}
                      </h3>
                      <p className="text-gray-700 text-sm mt-1 line-clamp-3">
                        {event.description}
                      </p>
                      <button className="mt-5 bg-customPurple text-white py-2 px-4 rounded-lg w-full hover:bg-purple-800 transition duration-300">
                        Buy Tickets
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {index < Object.keys(groupedEvents).length - 1 && (
                <div className="mt-8 border-t border-gray-300"></div>
              )}
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center mt-20 text-center">
            <h3 className="text-3xl font-bold text-gray-900">🎉 No Events Right Now!</h3>
            <p className="text-gray-600 mt-3 text-lg max-w-md">
              🚀 Exciting events are on the horizon! Stay tuned for upcoming surprises.
            </p>
          </div>
        )}
      </div>
    </div>
  );
  
};

export default EventsScreen;
