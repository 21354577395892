import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API; // Ensure this is set in your .env file

export const sendEmail = async (emailData) => {
  try {

    const response = await axios.post(`${API_BASE_URL}/send-event-confirmation`, emailData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error sending email:", error);
    return null;
  }
};
