import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { supabase } from '../client';
import { showToastFailure } from '../services/toast';
import { useLoading } from '../contexts/LoadingContext';
import { getFormattedDate } from '../utils/formattedDate';
import { SelectDatepicker } from 'react-select-datepicker';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Alert from '../modals/Alert';

// Define the schema using yup
const schema = yup.object({
    first_name: yup.string()
        .required('First name is required')
        .matches(/^[a-zA-Z\s]*$/, 'First name must only contain letters and spaces'), // Ensure no special characters
    last_name: yup.string()
        .required('Last name is required')
        .matches(/^[a-zA-Z\s]*$/, 'Last name must only contain letters and spaces'), // Ensure no special characters
    email: yup.string()
        .email('Invalid email address')
        .required('Email is required')
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Invalid email address format'), // More restrictive email validation
    password: yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .matches(/^[\w@$!%*?&]*$/, 'Password contains invalid characters'), // Ensure no JavaScript characters or tags
    dob: yup.date()
        .required('Date of birth is required'),
    confirm_password: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    phone: yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
});

const Registration = () => {
    const { showLoader, hideLoader } = useLoading();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState({
        title: '',
        description: '',
        buttons: [],
    });

    const handleButtonClick = (value) => {
        setShowAlert(false);
    };

    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);


    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (formData) => {
        try {
            showLoader();

            // Check if email or phone already exists
            const { data: existingUser } = await supabase
                .from('users')
                .select('id')
                .or(`email.eq.${formData.email},phone.eq.${formData.phone}`)
                .single();

            if (existingUser) {
                setAlertContent({
                    title: 'Registration Failed',
                    description: 'Mobile or email already exists, please try different email or mobile number to create a new account!',
                    buttons: [{ label: 'OK', value: 'ok' }],
                });
                setShowAlert(true);
            } else {
                // Sign up the user and handle response
                const { data } = await supabase.auth.signUp({
                    email: formData.email,
                    password: formData.password,
                    data: {
                        phone: formData.phone
                    }
                });

                if (data?.user) {
                    // Save the user details in the users table
                   await supabase
                        .from('users')
                        .insert([
                            {
                                user_id: data.user.id,
                                first_name: formData.first_name,
                                last_name: formData.last_name,
                                email: formData.email,
                                phone: formData.phone,
                                is_active: true,
                                role: 'user',
                                dob: formData.dob,
                                created_at: getFormattedDate(new Date())
                            },
                        ]);

                

                    // Success message or further actions
                    setAlertContent({
                        title: 'Registration Success',
                        description: 'User registered successfully please check your email and confirm',
                        buttons: [{ label: 'OK', value: 'ok' }],
                    });
                    setShowAlert(true);
                    reset();
                }

            }

        }catch (error) {
            showToastFailure(error.message);
        } finally {
            hideLoader();
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <Controller
                    name="first_name"
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="First Name"
                            fullWidth
                            margin="normal"
                            error={!!errors.first_name}
                            helperText={errors.first_name?.message}
                        />
                    )}
                />

                <Controller
                    name="last_name"
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Last Name"
                            fullWidth
                            margin="normal"
                            error={!!errors.last_name}
                            helperText={errors.last_name?.message}
                        />
                    )}
                />

                <Controller
                    name="email"
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Email"
                            fullWidth
                            margin="normal"
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                    )}
                />

                <Controller
                    name="phone"
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Phone"
                            fullWidth
                            margin="normal"
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                            inputProps={{
                                maxLength: 10, // Limit input to 10 characters
                            }}
                        />
                    )}
                />
                <div className="mb-4 border border-gray-300 rounded-lg p-4">
                    <label className="block text-sm font-medium text-gray-700">Date of Birth</label>

                    <Controller
                        name="dob"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <div className="w-full max-w-xs sm:max-w-md lg:max-w-lg"> {/* Adjust width for different screen sizes */}
                                <SelectDatepicker
                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                                    selectedDate={field.value ? new Date(field.value) : null}
                                    onDateChange={(date) => {
                                        if (date instanceof Date && !isNaN(date.getTime()) && date.getTime() !== new Date(field.value).getTime()) {
                                            field.onChange(date); // Update form field with the valid date
                                        }
                                    }}
                                    className="w-full"
                                    labels={{
                                        yearPlaceholder: 'YYYY',
                                        dayPlaceholder: 'DD',
                                        monthPlaceholder: 'MM'
                                    }}
                                />
                            </div>
                        )}
                    />

                    {errors.dob && <p className="text-red-600 text-sm">{errors.dob.message}</p>} {/* Display error message */}
                </div>


                <Controller
                    name="password"
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            margin="normal"
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                <Controller
                    name="confirm_password"
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            fullWidth
                            margin="normal"
                            error={!!errors.confirm_password}
                            helperText={errors.confirm_password?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowConfirmPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                {/* Password Requirements Note */}
                <p className="text-black text-bold mt-4">
                    Password must contain:
                    <ul className="list-disc list-inside">
                        <li>At least one uppercase letter</li>
                        <li>At least one lowercase letter</li>
                        <li>At least one number</li>
                        <li>At least one special character (e.g., @$!%*?&)</li>
                    </ul>
                </p>

                <Button type="submit"
                    sx={{
                        backgroundColor: '#23102A',
                        color: '#FFFFFF',
                        marginTop: '10px'
                    }} variant="contained"
                    fullWidth>
                    Register
                </Button>
            </form>

            {showAlert && (
                <Alert
                    title={alertContent.title}
                    description={alertContent.description}
                    buttons={alertContent.buttons}
                    onButtonClick={handleButtonClick}
                    onClose={handleButtonClick}
                />
            )}
        </>

    );
};

export default Registration;
